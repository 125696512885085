import CustomDropdown from "../../../components/layout/dropdown";
import { useState } from "react";

function Table() {
  const [dropdownOptions] = useState([
    { label: '処理を選択してください', value: '' },
  ]);
  const [pageOptions] = useState([
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 }
  ]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const [pageValue, setPageValue] = useState(pageOptions[0]);
 
  return (
    <div className="Table">
        <div className="px-5">
          <div className="py-4">
            <div className="mb-4 text-gray-900">
                <p className="text-[18px] mb-4 font-bold">お知らせ</p>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="mr-5 inline-block">全248件</span>
                  <div className="custom-dropdown relative inline-block min-w-[200px] text-left" >
                    <div>
                      <CustomDropdown options={dropdownOptions} selectedValue={selectedValue}/>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <div className="flex items-center justify-end gap-5">
                    <div>
                      <button className="box-shadow-form h-9 min-w-[72px] rounded bg-sky-600 px-3 py-2 font-bold text-white focus:outline-sky-600">
                        お知らせ登録
                      </button>
                    </div>
                    <div className="w-[64px]">
                      <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                    </div>
                    <nav
                      className="box-shadow-form h-9">
                      <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <span
                            aria-current="page"
                            className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                          >
                            1/4
                          </span>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              data-slot="icon"
                              className="h-5 w-5 text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto lg:static xl:overflow-x-visible">
              <table className="custom-table table-fixed w-full border border-gray-200 text-left text-gray-900 rtl:text-right">
                <thead className="text-sm uppercase leading-custom font-medium">
                  <tr className="h-[41px] font-bold">
                    <th className="border bg-gray-100 px-3 py-2 w-[48px]">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="checkbox_all" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[103px]">
                    告知日
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[103px]">
                    種別
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[580px]">
                    タイトル
                    </th>
                    <th className="border bg-gray-100 px-3 py-2  w-[100px]">
                    ステータス
                    </th>
                    <th className="border bg-gray-100 px-3 py-2 w-[100px]">
                    メール通知
                    </th>
                  </tr>
                </thead>
                <tbody className="leading-custom text-sm">
                  <tr className="bg-white hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-11-14
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    お知らせ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 focus:outline-sky-600">
                    【アップデート完了報告】 2024年11月13日 (水)
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-11-11
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    お知らせ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 focus:outline-sky-600">
                    今週のメンテナンス中における在庫連携についてのご案内
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-[#E5D1CF] !text-sky-600 hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-11-06
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    重要
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    LINEギフト 出荷指示取得APIでの注文取得漏れリスクにつきまして
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-[#E5D1CF] !text-sky-600 hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-11-05
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    重要
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    データベース再起動のため、在庫連携を一時停止させて頂きます。
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-10-29
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    お知らせ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 focus:outline-sky-600">
                    データベース再起動のため、 在庫連携を一時停止させて頂きます。
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-10-24
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    お知らせ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 focus:outline-sky-600">
                    【アップデート完了報告】 2024年10月23日(水)
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-[#E5D1CF] !text-sky-600 hover:bg-[#F0F0F0] h-[41px] ">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-10-22
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    重要
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    【重要】 サーバーオプティマイズ作業に伴うお願い/在庫連携停止のお知らせ (server1)
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-10-22
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    お知らせ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 focus:outline-sky-600">
                    今週のメンテナンス中における在庫連携についてのご案内
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-10-18
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    お知らせ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 focus:outline-sky-600">
                    配送状況ステータス更新の不具合について
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-[#E5D1CF] !text-sky-600 hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-10-16
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    重要
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    本日データベース再起動のため、 在庫連携を一時停止させて頂きます。
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-10-10
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    お知らせ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 focus:outline-sky-600">
                    【アップデート完了報告】 2024年10月09日 (水)
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-10-08
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    お知らせ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 focus:outline-sky-600">
                    今週のメンテナンス中における在庫連携についてのご案内
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-10-07
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    お知らせ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 focus:outline-sky-600">
                    本日 データベース再起動のため、 在庫連携を一時停止させて頂きます。
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-[#EFDFDD] !text-sky-600 hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-10-02
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    重要
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    データベース再起動のため、 在庫連携を一時停止させて頂きます。
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-[#EFDFDD] !text-sky-600 hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-09-30
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    重要
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    データベース再起動のため、 在庫連携を一時停止させて頂きます。
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-09-26
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    お知らせ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 focus:outline-sky-600">
                    【アップデート完了報告】 2024年09月25日 (水)
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-[#EFDFDD] !text-sky-600 hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-09-25
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    重要
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    本日のメンテナンス中における在庫連携停止時間変更のご案内
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-09-24
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    お知らせ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 focus:outline-sky-600">
                    今週のメンテナンス中における在庫連携についてのご案内
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-09-24
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    お知らせ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 focus:outline-sky-600">
                    【お知らせ】 「フリー在庫アラート」 「在庫アラート」 「出荷指示保留通知」 通知機能不具合について
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-[#F0F0F0] h-[41px]">
                    <td className="border border-gray-200 px-3 py-2">
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-white text-sky-600 focus:ring-sky-600 cursor-pointer"
                        />
                        <label htmlFor="1" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    2024-09-19
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    お知らせ
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-sky-600 focus:outline-sky-600">
                    【お知らせ】 出荷指示保留通知」について
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top text-[#E11D48]">
                    未統
                    </td>
                    <td className="border border-gray-200 px-3 py-2 align-top">
                    送信済み
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-end gap-5">
                <div className="w-[64px]">
                    <CustomDropdown options={pageOptions} selectedValue={pageValue}/>
                </div>
                <nav className="box-shadow-form h-9" aria-label="Table navigation">
                  <ul className="inline-flex -space-x-px text-xs rtl:space-x-reverse">
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-s border border-gray-300 px-1.5 text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <span
                        aria-current="page"
                        className="flex h-9 items-center justify-center border border-x-0 border-gray-300 px-3 text-gray-900"
                      >
                        1/4
                      </span>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="flex h-9 items-center justify-center rounded-e border border-gray-300 bg-white px-1.5 leading-tight text-gray-900 focus:outline-sky-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="h-5 w-5 text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Table;
